import React, { type FC } from 'react';
import PropTypes from 'prop-types';

import { Link } from '@confluence/route-manager';
import { CONTEXT_PATH } from '@confluence/named-routes';

export const PeopleLink: FC<any> = (props) => {
	const { href, to, ...otherProps } = props;
	const url = to || href;
	const path = url && url.indexOf('/') !== 0 ? `/${url}` : url;
	const destination = !path.startsWith(CONTEXT_PATH) ? `${CONTEXT_PATH}${path}` : path;

	return <Link {...props} href={destination} {...otherProps} />;
};

PeopleLink.propTypes = {
	href: PropTypes.string,
	to: PropTypes.string,
};
