import { EDIT_PAGE_V2, EDIT_BLOG_V2, COMPANY_HUB_EDIT } from '@confluence/named-routes';

import { useRouteName } from './RouteState';

const isOnEditorPageRouteArgs = {
	selector: (routeName: string | undefined) =>
		routeName === EDIT_PAGE_V2.name ||
		routeName === EDIT_BLOG_V2.name ||
		routeName === COMPANY_HUB_EDIT.name,
};

// Returns whether you're on edit page or edit blog route.
// Note: The editor is also visible on live pages, which this hook does _not_ check for.
export const useIsEditorPage = (): boolean => useRouteName(isOnEditorPageRouteArgs);
