import { textNormalize } from './highlightActionsUtils';

const TEXT_NODE_TYPE = 3;

declare global {
	interface Document {
		createNodeIterator(
			root: Node,
			whatToShow: number,
			filter: NodeFilter,
			entityReferenceExpansion: boolean,
		);
	}
}

/**
 * This is a modification of C++'s upper_bound which find the index of number that is greater than "search".
 * Subtracting the index by one we can get the index that is less or equal to the given "search"
 * result >= 0 && result <= len - 1
 * @private export for testing
 */
export function findIndexLessOrEqualFrom(list: number[], search: number, from: number) {
	const startPosInRange = Math.min(from, list.length - 1);
	let l = startPosInRange;
	let r = list.length;
	while (l < r) {
		// This is a LEGACY disable statement after rule addition. Use `Math.floor((l+r)/2)` next time
		// eslint-disable-next-line no-bitwise
		const mid = (l + r) >> 1;
		if (list[mid] <= search) {
			l = mid + 1;
		} else {
			r = mid;
		}
	}
	return Math.max(l - 1, startPosInRange);
}

/**
 * Concatenate all the text from container into a big string.
 * Note down the the start positions.
 *
 * For example, if the contains has following text nodes: ["hello", "wo", "rld"]
 * text = "helloworld"
 * nodesStartPos = [0, 5, 7]
 * nodes = [<reference to "hello">, <reference to "wo">, <reference to "rld">]
 *
 * The reason of putting all the text into one array is the selected text might across multiple text nodes.
 * For example: "lloworl" spans across all 3 text nodes.\
 */
export function indexTextNodes(root, ignoredNodes) {
	let textNode;
	let text = '';
	const nodesStartPos: number[] = [];
	const nodes: Text[] = [];
	const nodeFilter = (textNode) => {
		/**
		 * If the text node is a descendent of one of the ignored node.
		 * It means the text was rendered by maybe a macro.
		 * Ignore these text nodes.
		 */
		return ignoredNodes.some((node) => node === textNode || node.contains(textNode))
			? NodeFilter.FILTER_REJECT
			: NodeFilter.FILTER_ACCEPT;
	};
	// createNodeIterator accepts an object with acceptNode field as node filter.
	// However IE 11 is expecting a function directly.
	nodeFilter.acceptNode = nodeFilter;

	const iter = document.createNodeIterator(root, NodeFilter.SHOW_TEXT, nodeFilter, false);
	while ((textNode = iter.nextNode() as Text)) {
		const normalizedText = textNormalize(textNode.nodeValue);

		// WS-2885 - \u0020 represents the spacebar character so this regex handles any number of space only characters
		//           that may exist as their own nodes. We don't need to worry about only commenting on a space here
		//           because the popup has logic to only show itself if more than just a space is selected.
		if (/^\u0020+$/.test(normalizedText) || normalizedText.trim()) {
			// If it is not completely empty
			nodesStartPos.push(text.length);
			nodes.push(textNode);
			text += normalizedText;
		}
	}
	return { text, nodesStartPos, nodes };
}

function getNodeTextLength(node: Node | null): number {
	return node && node.nodeValue ? node.nodeValue.length : 0;
}

/**
 * Returns the number of times the rawSelectedtext is found in a given text body,
 * as well as, the index of the match we want to highlight.
 *
 * For example: "o" appears two times in "helloworld". numMatches = 2
 * And we highlighted the second occurrence. index = 1;
 *
 * @param text All the text in the content body.
 * @param rawSelectedText Primitive text that was highlighted.
 * @param startContainer The text node that contains the start of the selected text.
 * @param startOffset A selected text can be part of a text node.
 * @param nodesStartPos Array of indices for where each text node of text starts
 * @param nodes References to text nodes.
 */
export function findMatches(
	text: string,
	rawSelectedText: string,
	startContainer: Node,
	startOffset: number,
	nodesStartPos: number[],
	nodes: Text[],
	includeTraceForMatches?: boolean,
) {
	const matchTraceList: string[] = [];
	if (!startContainer) {
		return { numMatches: 0, index: -1, matchTraceList: null };
	}

	let start = 0;
	let nodeStart = 0;
	let found = 0;
	let index = -1;
	let numMatches = 0;

	// loop through the provided text body string, starting from the beginning,
	// and update the index to search from until the rawSelectedtext can
	// no longer be found
	while ((found = text.indexOf(rawSelectedText, start)) !== -1) {
		// nodesStartPos is built by slowly concatenanting each non-empty text node value
		// together into a single string, and storing the index number at which the next string starts

		// with the matched index, find the nearest index value but return that index-1.
		// For example, if our match index is 10 and nodesStartPos = [0, 5, 11, 13],
		// we return index 1 (11 is the nearest but we want the index-1, 5).
		const pos = findIndexLessOrEqualFrom(nodesStartPos, found, nodeStart);
		const textNode = nodes[pos];

		// In Firefox there is a case that startOffset is the end of a text node.
		// In this case it means the selection is actually starting from the next sibling node.
		let startNode: Node | null = startContainer;
		if (startNode.nodeType === TEXT_NODE_TYPE && startOffset >= getNodeTextLength(startNode)) {
			startOffset -= getNodeTextLength(startNode);
			startNode = startContainer.nextSibling;
		}

		if (
			startNode &&
			((startNode.nodeType === TEXT_NODE_TYPE && textNode === startNode) ||
				// Text node in IE 11 doesn't have contains method
				(startNode.nodeType !== TEXT_NODE_TYPE && startNode.contains(textNode)))
		) {
			// The current text node might be the one we are selecting.
			// However a single text may have more than one match of the selected text.
			// We need to double check if the offset is correct.
			const offsetForCurrentFoundText = found - nodesStartPos[pos];
			if (offsetForCurrentFoundText <= startOffset) {
				index = numMatches;
			}
		}

		// Trace the text node back to the root if the flag is true
		if (includeTraceForMatches) {
			let element: Element | null = textNode.parentElement;
			const parentElements: string[] = [];

			// We want to loop and record all the elements up to the div#content node
			while (element && element.getAttribute('id') !== 'content') {
				let classes = '';
				let attributes = '';
				const { tagName } = element;
				const classList = element.classList ? element.classList.value : '';
				const dataAttributes = element
					.getAttributeNames()
					.filter((attribute) => attribute.indexOf('data-') !== -1);

				// Handle classes
				if (classList.length) {
					classes = `.${classList.split(' ').join('.')}`;
				}

				// Handle data attributes
				if (dataAttributes.length) {
					dataAttributes.forEach((attribute) => {
						// We don't want to get the value of the data-macro-body and data-title attributes
						// because they can contain user information and we don't want to record it
						if (attribute !== 'data-macro-body' && attribute !== 'data-title') {
							// While it's "possible", we'll never actually get here if element is null
							const value = element?.getAttribute(attribute);
							attributes += `[${attribute}="${value}"]`;
						} else {
							attributes += `[${attribute}]`;
						}
					});
				}

				parentElements.unshift(`${tagName}${classes}${attributes}`);
				element = element.parentElement;
			}

			matchTraceList.push(parentElements.join(' > '));
		}

		numMatches++;
		start = found + 1;
		nodeStart = pos;
	}

	return {
		numMatches,
		index,
		matchTraceList: matchTraceList.length ? matchTraceList : null,
	};
}
