/* eslint-disable no-restricted-globals */
import { textNormalize } from './highlightActionsUtils';
import { indexTextNodes, findMatches } from './findMatches';

type SelectionRange = {
	top?: number;
	bottom?: number;
	left?: number;
	right?: number;
	width?: number;
	height?: number;
};

declare const $: any;

/**
 * Inline comments can only be added at text that is either part of the page or macro body.
 * Skip text that is rendered by the macro itself.
 */
export const IGNORE_SELECTORS = [
	// Legacy mention
	'.user-mention',
	// Fabric mention
	'[data-mention-id]',
	// Connect macro
	'.ap-container',
	// Links
	'a[href^="/"]',
	// Macros that doesn't have body
	'.conf-macro[data-hasbody="false"]',
	// Normal status
	'.status-lozenge-span',
	// Date will get rendered as "Today" in fabric
	'.date-lozenger-container',
	// Inline Smart Card/Jira issue link
	'[data-inline-card="true"]',
	'.inlineCardView-content-wrap',
	'.smart-link-title-wrapper',
	// Actual jira issue macro
	'.jira-issue',
	// Title for expand
	'.ak-renderer-extension-overflow-container button',
	'[data-node-type="expand"] > button',
	'[data-node-type="nestedExpand"] > button',
	// JIRA Macro
	'.jira-issues',
	// datasource table for new JIM macro
	'.datasource-table',
	// Inline file attachments
	"[data-type='file']",
	// Fabric code snippets
	'.code-block',
	// Legacy code snippets
	'.conf-macro[data-macro-name="code"]',
	// Sticky table headers (WS-2345 - Sticky header comment flow is strange for now, restrict until we can find a better solution)
	'.pm-table-sticky-wrapper',
	// Block cards
	'.blockCardView-content-wrap',
	// Excerpt Includes (WS-2563)
	'.ak-excerpt-include',
	// View All Container
	'#view-all-inline-comments-container',
	// Strikethrough
	's',
	// Emoji
	'[data-emoji-id]',
	// Charts
	"[data-mark-type='dataConsumer']",
	// Reattach Dialog
	'#reattach-container',
	// BEGIN EDITOR EXCLUSIONS
	//
	// Gadget?
	"[extensionkey='gadget']",
	// placeholder content
	'span[data-placeholder]',
	// covers most macros, would this be too encompassing?
	"[contenteditable='false']",
	// page include macro
	"[data-node-type='include']",
	// page properties report adf macro
	'.page-properties-report',
].join(',');

function getSelectionHTML(selectionRange: Range): string {
	return $('<div>')
		.append(selectionRange.cloneContents() as any)
		.html();
}

function getContainingElement(selectionRange: Range) {
	if (selectionRange.commonAncestorContainer) {
		const selectRangeElement = selectionRange.commonAncestorContainer;
		if (selectRangeElement.nodeType === 3) {
			//Is TextNode
			return selectRangeElement.parentNode;
		}
		return selectRangeElement;
	}
}

function getFirstAndLastSelectionRect(selectionRange, clientRects) {
	// In Chrome triple clicking text will cause all the text in an element to be selected. When calling
	// getClientRects after a triple click, the array will contain rects for each line of highlighted text
	// in the highlighted element and also a clientRects for the adjacent sibling element even though no text
	// is highlighted inside it. If the endOffset is zero, we ignore this final clientRect because no text
	// is highlighted inside of it.
	//
	// Also, in Chrome, Safari and Firefox, get client rects return a clientRects representing each inline element
	// including rectangles for all nested elements. IE8 return many less rects, maybe just one for the ancestor
	// element containing the highlight or a clientRects for each line of highlight without individual rects for each
	// inline element
	const rects: { first: SelectionRange; last: SelectionRange } = {
		first: clientRects[0],
		last: clientRects[clientRects.length - 1],
	};

	if (selectionRange.endOffset !== 'undefined') {
		//In IE, if we highlight on resolved inline comment, we will length of clientRects is 1
		if (selectionRange.endOffset === 0 && clientRects.length > 1) {
			rects.last = clientRects[clientRects.length - 2];
		}
	}
	return rects;
}

function getSelectionRects(selectionRange) {
	// if using documentation theme, get scrolltop of documentation theme content which is fixed size
	// certain browsers give the scrollTop on html element, some give it on the body element. this works in both.
	const scrollTop = window.scrollY;
	const scrollLeft = window.scrollX;

	let clientRects = selectionRange.getClientRects();
	// IE fails to provide client rects when highlighting a paragraph containing only an image or triple clicking to
	// highlight any paragraph containing content other than plain text. In this case, it is safe to assume parent
	// element dimensions will represent the highlighted area
	if (!clientRects.length && selectionRange.parentElement && selectionRange.parentElement()) {
		const $parentElement = $(selectionRange.parentElement());
		const parentOffset = $parentElement.offset();

		if (parentOffset) {
			clientRects = [
				{
					top: parentOffset.top - scrollTop,
					left: parentOffset.left - scrollLeft,
					bottom: parentOffset.top + ($parentElement.height() || 0),
					right: parentOffset.left + ($parentElement.width() || 0),
				},
			];
		}
	}
	const rects = getFirstAndLastSelectionRect(selectionRange, clientRects);

	/*
	 * Calculates Create Issue dialog target area
	 */
	const getOverlap = function (firstRect: SelectionRange, lastRect: SelectionRange) {
		const overlap: SelectionRange = {};
		overlap.top = firstRect.top;
		overlap.left = (firstRect.left || 0) + scrollLeft;
		overlap.bottom = lastRect.bottom;

		if (
			firstRect.left !== undefined &&
			firstRect.right !== undefined &&
			lastRect.left !== undefined &&
			lastRect.right !== undefined
		) {
			if (firstRect.left >= lastRect.right) {
				overlap.right = firstRect.right;
			} else {
				overlap.right = lastRect.right;
			}
		}

		overlap.right = (overlap.right || 0) + scrollLeft;
		// adjust top for doc theme
		overlap.top = (overlap.top || 0) + scrollTop;
		overlap.bottom = (overlap.bottom || 0) + scrollTop;
		// set width and height
		overlap.width = overlap.right - overlap.left;
		overlap.height = overlap.bottom - overlap.top;

		return overlap;
	};

	/*
	 * Calculates the action panel target area
	 */
	const getHighlightStart = function (rect) {
		const highlight: SelectionRange = {};
		if (!rect) {
			return highlight;
		}

		highlight.width = rect.right - rect.left;
		highlight.height = rect.bottom - rect.top;
		highlight.left = rect.left + scrollLeft;
		highlight.right = rect.right + scrollLeft;
		highlight.top = rect.top + scrollTop;
		highlight.bottom = rect.bottom + scrollTop;
		return highlight;
	};

	return {
		first: getHighlightStart(rects.first),
		average: getOverlap(rects.first, rects.last),
	};
}

function getSelectionText(selectionRange: Range): string {
	return textNormalize(String(selectionRange));
}

/**
 * Generates javascript object containing the context of the selected text to help locate in storage format
 *
 * @param root container of content which can be selected
 * @param selectedText text that currently highlighted
 * @param selected range object representing the current selection
 * @param pageId the page id we're currently on
 * @param includeTraceForMatches a boolean stating whether or not to include a tree trace for each match
 * @return object containing metadata about the location of the selected text
 */
function computeSearchTextObject(root, selectedText, selected, pageId, includeTraceForMatches) {
	// WS-2345 - Sticky header create comment flow is strange for now, disabling it until a better solution is found
	// https://product-fabric.atlassian.net/browse/CEMS-1100
	//
	// sticky headers create duplicate tables to work, which causes duplicate
	// text content to end up in the inline comment text string. ignore sticky
	// table content when not visible, and vice versa.
	// const inSticky = findStickyHeader(selected.startContainer);
	// const ignoreTable: HTMLElement[] = Array.from(
	//   inSticky
	//     ? root.querySelectorAll(
	//         ".pm-table-container .pm-table-wrapper tr:first-child"
	//       )
	//     : root.querySelectorAll(".pm-table-sticky-wrapper")
	// );

	const ignoreTable: HTMLElement[] = Array.from(root.querySelectorAll('.pm-table-sticky-wrapper'));

	const ignoredNodes: HTMLElement[] = Array.from(root.querySelectorAll(IGNORE_SELECTORS));

	const { text, nodesStartPos, nodes } = indexTextNodes(root, [...ignoredNodes, ...ignoreTable]);

	const { index, numMatches, matchTraceList } = findMatches(
		text,
		selectedText,
		selected.startContainer,
		selected.startOffset,
		nodesStartPos,
		nodes,
		includeTraceForMatches,
	);

	const searchTextObj: any = {
		pageId,
		selectedText,
		index,
		numMatches,
	};

	if (matchTraceList) {
		searchTextObj.matchTraceList = matchTraceList;
	}

	return searchTextObj;
}

export function convertSelectionToLegacyFormat(
	contentContainer,
	selectionRange,
	pageId,
	lastModified?,
	getTraceForMatches?,
) {
	const rawText = getSelectionText(selectionRange);
	const text = rawText.trim();

	let searchText;
	// It finds all the occurrences of selected text.
	// Since if I select word "is" there maybe a lot occurrences on the page.
	// searchText.index tells the backend which one is really the one I selected.
	// That is among, for example 20 matches, the 5th match is my selection.
	if (text) {
		searchText = computeSearchTextObject(
			contentContainer,
			// It is important to pass raw text here
			rawText,
			selectionRange,
			pageId,
			getTraceForMatches,
		);
	}

	return getTraceForMatches
		? searchText.matchTraceList
		: {
				text,
				searchText,
				area: getSelectionRects(selectionRange),
				html: getSelectionHTML(selectionRange),
				containingElement: getContainingElement(selectionRange),
				range: selectionRange,
				lastModified,
			};
}

export function getMatchTraces(contentContainer, selectionRange, pageId) {
	const matchTraceList = convertSelectionToLegacyFormat(
		contentContainer,
		selectionRange,
		pageId,
		null,
		true,
	);

	return matchTraceList || [];
}
