import React, { createContext, useContext, useMemo, useState } from 'react';
import type { FC } from 'react';

export type DocumentUpdateStatusContextType = {
	documentUpdated: boolean | null;
	documentNeedsUpdate: boolean | null;
	publishedDocumentVersion: number;
};

export type DocumentUpdateStatusDispatchContextType = {
	setDocumentUpdated: (documentUpdated: boolean) => void;
	setDocumentNeedsUpdate: (documentNeedsUpdate: boolean) => void;
	setPublishedDocumentVersion: (publishedDocumentVersion: number) => void;
};

export const DocumentUpdatedContext = createContext<DocumentUpdateStatusContextType>({
	documentUpdated: false,
	documentNeedsUpdate: false,
	publishedDocumentVersion: 0,
});
DocumentUpdatedContext.displayName = 'DocumentUpdateStatusContext';

export const DocumentUpdatedDispatchContext =
	createContext<DocumentUpdateStatusDispatchContextType>({
		setDocumentUpdated: () => {},
		setDocumentNeedsUpdate: () => {},
		setPublishedDocumentVersion: () => {},
	});
DocumentUpdatedDispatchContext.displayName = 'DocumentUpdateStatusDispatchContext';

type DocumentUpdateStatusProviderProps = {
	children: JSX.Element;
};

export const DocumentUpdateStatusProvider: FC<DocumentUpdateStatusProviderProps> = ({
	children,
}) => {
	const [documentUpdated, setDocumentUpdated] = useState(false);
	const [documentNeedsUpdate, setDocumentNeedsUpdate] = useState(false);
	const [publishedDocumentVersion, setPublishedDocumentVersion] = useState(0);

	const providerValue = useMemo(
		() => ({
			documentUpdated,
			documentNeedsUpdate,
			publishedDocumentVersion,
		}),
		[documentUpdated, documentNeedsUpdate, publishedDocumentVersion],
	);

	const dispatchProviderValue = useMemo(
		() => ({
			setDocumentUpdated,
			setDocumentNeedsUpdate,
			setPublishedDocumentVersion,
		}),
		[setDocumentUpdated, setDocumentNeedsUpdate, setPublishedDocumentVersion],
	);

	return (
		<DocumentUpdatedContext.Provider value={providerValue}>
			<DocumentUpdatedDispatchContext.Provider value={dispatchProviderValue}>
				{children}
			</DocumentUpdatedDispatchContext.Provider>
		</DocumentUpdatedContext.Provider>
	);
};

export const useDocumentUpdateStatus = () => {
	return useContext(DocumentUpdatedContext);
};

export const useDocumentUpdateStatusDispatch = () => {
	return useContext(DocumentUpdatedDispatchContext);
};
