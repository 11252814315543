import type { ComponentType, ReactElement, ReactNode } from 'react';
import React from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Container } from 'unstated';

import type { ModalDialogProps } from '@atlaskit/modal-dialog';

import { dialogKeyState } from './dialog-key-state';

export type ModalFrame = {
	/**
	 * unique identifier for stack frame
	 */
	key: number;
	/**
	 * dialog to show (React element)
	 */
	component: ReactElement;
	/**
	 * should be mounted
	 */
	alive: boolean;
	originalProps: Partial<DialogsContainerProps>;
};

/**
 * component's original props
 */
export type DialogsContainerProps = {
	onClose(...args: Partial<ModalDialogOnCloseArgs>): void;
	onCloseComplete(...args: Partial<ModalDialogOnCloseCompleteArgs>): void;
};

export type ModalDialogOnCloseArgs = Parameters<Required<ModalDialogProps>['onClose']>;
export type ModalDialogOnCloseCompleteArgs = Parameters<
	Required<ModalDialogProps>['onCloseComplete']
>;

type DialogsStateContainerState = {
	stack: ModalFrame[];
};

export class DialogsStateContainer extends Container<DialogsStateContainerState> {
	constructor(stack: ModalFrame[] = []) {
		super();

		/*
      Arguably, nextKey belongs in state (as per 'flags' package). But then there's
      an unenviable choice of, in showDialog, putting the React.createElement in the
      setState callback, or giving setState an object instead of a callback and
      risk having two dialogs opened with the same key in the (unlikely) scenario
      that they're both opened in the same tick.
    */

		this.state = { stack };

		// If you're pre-supplying a stack, make nextKey bigger than any of its keys
		if (stack.length) {
			const maxKey = Math.max(...stack.map((frame) => frame.key));
			dialogKeyState.nextKey = maxKey + 1;
		}
	}

	showModal = <T extends DialogsContainerProps>(
		componentClass: ComponentType<T>,
		originalProps?: Omit<T, keyof DialogsContainerProps> & Partial<DialogsContainerProps>,
		children?: ReactNode,
	): void => {
		this.showDialog(componentClass, originalProps, children);
	};

	/**
	 * @deprecated use `.showModal()` instead (see "next/packages/dialogs/README.md" for migration instructions)
	 */
	showDialog = <T extends {}>(
		componentClass: ComponentType<T>,
		originalProps?: Omit<T, keyof DialogsContainerProps> & Partial<DialogsContainerProps>,
		children?: ReactNode,
	) => {
		const key = dialogKeyState.nextKey;
		const props: any = originalProps || {};

		const component = React.createElement(
			componentClass,
			{
				...props,
				onClose: (...args: any[]) => this.onChildClose(key, ...args),
				onCloseComplete: (...args: any[]) => this.onChildCloseComplete(key, ...args),
			},
			children,
		);

		void this.setState((state) => ({
			stack: [
				...state.stack,
				{
					key,
					component,
					alive: true,
					originalProps: {
						onClose: 'onClose' in props ? props.onClose : undefined,
						onCloseComplete: 'onCloseComplete' in props ? props.onCloseComplete : undefined,
					},
				},
			],
		}));

		// Returns a method that the caller can use to close the dialog (though, most often,
		// a child would self-close)

		return () => {
			this.onChildClose(key);
		};
	};

	private onChildClose(key: number, ...args: Partial<ModalDialogOnCloseArgs>) {
		// A child dialog called its onClose prop

		// Find the stack frame for the respective child
		const childFrame = this.state.stack.find((frame) => frame.key === key);

		if (!childFrame) {
			// This is harmless: it can happen when trying to close the dialog multiple times from different places.
			return;
		}

		if (childFrame.originalProps.onClose) {
			childFrame.originalProps.onClose(...args);
		}

		// Don't remove this dialog from the stack; instead mark it as not alive. This will pull the
		// component from its enclosing <ModalTransition>, but keep the <ModalTransition> around, for now.
		// The child that we provide to the <ModalTransition> has an onCloseComplete prop, to trigger the
		// final cleanup.

		void this.setState((state) => ({
			stack: state.stack.map((frame) =>
				frame === childFrame
					? {
							...frame,
							alive: false,
						}
					: frame,
			),
		}));
	}

	private onChildCloseComplete(key: number, ...args: Partial<ModalDialogOnCloseCompleteArgs>) {
		// A child dialog called its onCloseComplete prop

		// Find the stack frame for the respective child
		const childFrame = this.state.stack.find((frame) => frame.key === key);

		if (!childFrame) {
			// This is harmless: it can happen when trying to close the dialog multiple times from different places.
			return;
		}

		if (childFrame.originalProps.onCloseComplete) {
			childFrame.originalProps.onCloseComplete(...args);
		}

		// The transition has completed, so complete the cleanup: remove the
		// frame from the stack to pull the <ModalTransition>.

		void this.setState((state) => ({
			stack: state.stack.filter((frame) => frame !== childFrame),
		}));
	}
}
