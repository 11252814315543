import React, { useState, useRef, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Box, xcss } from '@atlaskit/primitives';
import Avatar from '@atlaskit/avatar/Avatar';
import type { SizeType, AvatarClickEventHandler } from '@atlaskit/avatar/types';

import { useViewUserProfile } from '@confluence/global-operations';

import type { Trigger, TriggerPosition } from './ProfileCardWrapper';
import { ProfileCardWrapper } from './ProfileCardWrapper';

type ProfileAvatarProps = {
	userId?: string | null;
	src?: string;
	onClick?: AvatarClickEventHandler;
	canViewProfile?: boolean;
	isAnonymous?: boolean;
	size?: SizeType;
	trigger?: Trigger;
	name: string;
	stackIndex?: number;
	testId?: string;
	onVisibilityChange?: (isVisible: boolean) => void;
	position?: TriggerPosition;
	borderColor?: string;
	offset?: [number, number];
};

const awatarWrapperStyles = {
	base: xcss({
		display: 'inline-flex',
		backgroundColor: 'color.background.neutral.subtle',
		borderWidth: '0',
		padding: 'space.0',
		borderRadius: '50%',
		outlineOffset: 'space.0',
		':focus-within': {
			outlineStyle: 'solid',
			outlineWidth: '2px',
			outlineColor: 'color.border.focused',
		},
	}),
	cursor: xcss({
		cursor: 'pointer',
	}),
};

const i18n = defineMessages({
	label: {
		id: 'profile.avatar.image',
		defaultMessage: 'profile image of a user',
		description: 'label for an avatar or profile image of a user',
	},
	ariaLabel: {
		id: 'profile.avatar.ariaLabel',
		defaultMessage: 'More information about {name}',
		description: 'Aria label attribute of the avatar button',
	},
});

export const ProfileAvatar = ({
	userId,
	canViewProfile = true,
	isAnonymous = false,
	size = 'medium' as SizeType,
	src,
	onClick,
	trigger,
	name,
	stackIndex,
	testId,
	position,
	borderColor,
	offset,
}: ProfileAvatarProps) => {
	const intl = useIntl();
	const avatarSrc = isAnonymous || !canViewProfile || !src ? undefined : src;
	const { canViewUserProfile: hasViewUserProfilePermission } = useViewUserProfile();

	const avatarRef = useRef<HTMLSpanElement | null>(null);
	const [isVisible, setIsVisible] = useState(false);
	const [shouldReturnFocus, setShouldReturnFocus] = useState(false);
	const [wasKeyboardAction, setWasKeyboardAction] = useState(false);

	const handleVisibilityChange = (isVisible: boolean) => {
		setIsVisible(isVisible);
	};

	useEffect(() => {
		if (shouldReturnFocus && !isVisible && avatarRef.current) {
			avatarRef.current.focus();
			setShouldReturnFocus(false);
		}
	}, [isVisible, shouldReturnFocus]);

	const handleKeyDown: React.KeyboardEventHandler<HTMLSpanElement> = (e) => {
		e.stopPropagation();
		if (e.key === 'Enter') {
			setWasKeyboardAction(true);
			setIsVisible((prev) => !prev);
			setShouldReturnFocus(true);
		} else if (e.key === 'Escape' && isVisible && wasKeyboardAction) {
			setIsVisible(false);
			setWasKeyboardAction(false);
			setShouldReturnFocus(true);
		}
	};

	const Icon = (
		<Avatar
			src={avatarSrc}
			size={size}
			target="_blank"
			name={name}
			label={intl.formatMessage(i18n.label)}
			stackIndex={stackIndex}
			testId={testId}
			borderColor={borderColor}
			onClick={onClick}
		/>
	);

	return (
		<ProfileCardWrapper
			userId={userId}
			canViewProfile={canViewProfile}
			isAnonymous={isAnonymous}
			trigger={trigger}
			fullName={name}
			disabledAriaAttributes
			onVisibilityChange={handleVisibilityChange}
			position={position}
			offset={offset}
		>
			<Box
				xcss={[
					awatarWrapperStyles.base,
					canViewProfile && hasViewUserProfilePermission && awatarWrapperStyles.cursor,
				]}
				aria-haspopup="true"
				aria-expanded={isVisible}
				role="button"
				testId="avatarWrapperTestId"
				aria-label={intl.formatMessage(i18n.ariaLabel, { name })}
				tabIndex={0}
				ref={avatarRef}
				onKeyDown={handleKeyDown}
			>
				{Icon}
			</Box>
		</ProfileCardWrapper>
	);
};
